/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Layout,
  Menu,
  Modal,
  Typography,
} from "antd";
import "../App.css";
import { auth, database } from "../firebase";
import { useNavigate } from "react-router-dom";
import { get, ref } from "firebase/database";
import Title from "antd/es/typography/Title";
const { Header, Content } = Layout;
const { Text } = Typography;

function AfterLoginLayout({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/selection");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI CAT EXAM PREP",
      description: "Payment for CAT Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-catexam-ebfb8.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };

    processPayment(paymentDetails);
  };

  const handleManageSub = () => {
    setIsModalVisible(true);
  };

  const handleFrom = () => {
    window.open("https://google.com/", "_blank", "noopener");
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchData(currentUser);
      }
    });

    const fetchData = async (currentUser) => {
      try {
        const photoRef = ref(database, `Users/${currentUser?.uid}`);
        const userTypeRef = ref(database, `Users/${currentUser?.uid}/premium`);

        const photoSnapshot = await get(photoRef);
        const userTypeSnapshot = await get(userTypeRef);

        if (photoSnapshot.exists()) {
          setUser(photoSnapshot.val());
        }
        if (userTypeSnapshot.exists()) {
          setUserType(userTypeSnapshot.val());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    return () => unsubscribe();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="0">Hello {user?.name}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={handleFrom}>
        Give us your valuable feedback
      </Menu.Item>
      {/* <Menu.Item key="1" onClick={handleManageSub}>
        Manage Subscription
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key="2" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout className="after-login-layout">
        <Header className="App-header">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text className="middle-font" style={{ marginTop: "1rem" }}>
              CAT-Exam
            </Text>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                style={{ border: "none" }}
                onClick={(e) => e.preventDefault()}
              >
                <Avatar
                  style={{ backgroundColor: "#9b9b9b" }}
                  src={user?.photoUrl}
                />
              </Button>
            </Dropdown>
          </div>
        </Header>
        <Content className="content">{children}</Content>
      </Layout>
      <Modal
        title="Upgrade to Pro"
        centered
        open={isModalVisible}
        onOk={() => navigate("/selection")}
        onCancel={() => setIsModalVisible(false)}
        footer=""
      >
        <Title level={1}>₹499/month</Title>
        <Title level={4}>Become a Pro</Title>
        <ul>
          <li>50 AI Generated Question/day.</li>
          <li>Every Sunday Mock Test.</li>
          <li>5 CAT Questions</li>
          <li>
            Advanced report that will help you understand your weaknesses and
            strength
          </li>
          <li>Advanced feedback and material suggestions by our AI</li>
          <li>Advance Data reporting</li>
          <li>Weekly Newsletter feedbacks</li>
        </ul>
        <Flex justify="end">
          <Button
            disabled={userType}
            className="btn-login"
            onClick={handlePayment}
          >
            Upgrade to Pro
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

export default AfterLoginLayout;
