import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Radio,
  Alert,
  Space,
  Card,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Button,
  Select,
} from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import stop from "../assets/stop.png";
// import { analytics } from "../firebase";
import { database, auth } from "../firebase";
import { ref, get, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import "../styles/PracticeQuestion.css";
import { logEvent } from "firebase/analytics";

function PracticeQuestion() {
  const [timer, setTimer] = useState(0);
  const [question, setQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSolution, setShowSolution] = useState(false);
  const [todaysQuestions, setTodaysQuestions] = useState(0);
  const [overallQuestions, setOverallQuestions] = useState(0);
  const [dailyQuestions, setDailyQuestions] = useState(0);
  const [userId, setUserId] = useState();
  const [todaysDate, setTodaysDate] = useState();
  const [mainTopic, setMainTopic] = useState();
  const [subTopic, setSubTopic] = useState();
  const [isCorrect, setIsCorrect] = useState(false);
  const timerRef = useRef(null);
  const [userType, setUserType] = useState(null);
  const [user, setUser] = useState(null);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleForExhusted, setIsModalVisibleForExhusted] =
    useState(false);
  const [isModalRedirectVisible, setIsModalRedirectVisible] = useState(false);
  const [quecard, setQuecard] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState("None");
  const navigate = useNavigate();

  let readingComprehensionCount = 1;
  let logicalReasoningCount = 1;
  let dataInterpretationCount = 1;

  let storedDiffName = null;
  let storedSubtopicName = null;

  let excludeDiff = useMemo(() => ({ Easy: [], Medium: [], Hard: [] }), []);

  useEffect(() => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
  }, [question, showSolution]);

  // useEffect(() => {
  //   logEvent(analytics, "page_view", { title: "Practice Questions" });
  // }, []);

  const fetchTodaysQuestionForUser = useCallback(
    async (userId, questionLimit) => {
      try {
        const snapshot = await get(
          ref(database, `Users/${userId}/todays_question`)
        );
        const snapshotOverall = await get(
          ref(database, `Users/${userId}/Overall/total_question_Attempt`)
        );
        const snapshotDaily = await get(
          ref(
            database,
            `Users/${userId}/Daily/${todaysDate}/total_question_Attempt`
          )
        );
        if (snapshot.val() >= questionLimit) {
          message.info("You have used your daily limit. Come back tomorrow.");
          navigate("/dashboard");
        }
        setTodaysQuestions(snapshot.val() || 0);
        setOverallQuestions(snapshotOverall.val() || 0);
        setDailyQuestions(snapshotDaily.val() || 0);
      } finally {
        setIsLoading(false);
      }
    },
    [todaysDate, navigate]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        setTodaysDate(new Date().toISOString().split("T")[0].replace(/-/g, ""));
      } else {
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      const fetchUserData = async () => {
        try {
          const userRef = ref(database, `Users/${userId}`);
          const snapshot = await get(userRef);
          const userData = snapshot.val();
          if (userData) {
            setUser(userData);
            setUserType(userData.premium);
            setQuestionLimit(userData.premium ? 50 : 1000);
            fetchTodaysQuestionForUser(userId, userData.premium ? 50 : 1000);
          }
        } catch (error) {
          message.error(`Can Not fetch User Data`);
        } finally {
          setIsLoading(false);
        }
      };

      fetchUserData();
    }
  }, [userId, fetchTodaysQuestionForUser]);

  const fetchRandomSubtopicFromTopic = useCallback(
    async (mainTopic) => {
      let allowChange = false;

      switch (mainTopic) {
        case "Reading Comprehension":
          allowChange =
            readingComprehensionCount === 1 ||
            (readingComprehensionCount - 1) % 4 === 0;
          readingComprehensionCount++;
          break;
        case "Logical Reasoning":
          allowChange =
            logicalReasoningCount === 1 ||
            (logicalReasoningCount - 1) % 4 === 0;
          logicalReasoningCount++;
          break;
        case "Data Interpretation":
          allowChange =
            dataInterpretationCount === 1 ||
            (dataInterpretationCount - 1) % 4 === 0;
          dataInterpretationCount++;
          break;
        case "Verbal Ability":
          allowChange = true;
          break;
        case "Quantitative Ability":
          allowChange = true;
          break;
        default:
          allowChange = true;
      }
      if (!allowChange) {
        return { diffName: storedDiffName, subtopicName: storedSubtopicName };
      }
      const diffSnapshot = await database
        .ref(`Questions/${mainTopic}`)
        .once("value");
      let diffNames = Object.keys(diffSnapshot.val() || {});

      diffNames = diffNames.sort(() => 0.5 - Math.random());
      for (let diffName of diffNames) {
        let subtopicsSnapshot = await database
          .ref(`Questions/${mainTopic}/${diffName}`)
          .once("value");
        let subtopicNames = Object.keys(subtopicsSnapshot.val() || {});

        subtopicNames = subtopicNames.filter(
          (subtopic) => !excludeDiff[diffName].includes(subtopic)
        );
        if (subtopicNames.length > 0) {
          const randomIndexForSubtopic = Math.floor(
            Math.random() * subtopicNames.length
          );
          const selectedSubtopic = subtopicNames[randomIndexForSubtopic];
          setSubTopic(selectedSubtopic);
          setSelectedLevel(diffName);
          storedDiffName = diffName;
          storedSubtopicName = selectedSubtopic;

          return { diffName: diffName, subtopicName: selectedSubtopic };
        }
      }
      setQuecard(true);
      return { diffName: null, subtopicName: null };
    },
    [excludeDiff]
  );

  const fetchQuestionBySequenceId = useCallback(
    async (mainTopic, subtopicName, sequenceId, diffval) => {
      const questionSnapshot = await database
        .ref(`Questions/${mainTopic}/${diffval}/${subtopicName}`)
        .orderByChild("sequence_id")
        .equalTo(sequenceId)
        .once("value");
      const questionData = questionSnapshot.val();
      if (!questionData) {
        excludeDiff[diffval] = [...(excludeDiff[diffval] || []), subtopicName];
        const newAttempt = await fetchRandomSubtopicFromTopic(mainTopic);
        if (newAttempt.diffName === null || newAttempt.subtopicName === null) {
          return null;
        }
        return await fetchQuestionForUser(
          mainTopic,
          newAttempt.subtopicName,
          newAttempt.diffName
        );
      }
      const key = Object.keys(questionData)[0];
      return questionData[key];
    },
    [excludeDiff, fetchRandomSubtopicFromTopic, fetchQuestionForUser]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function fetchQuestionForUser(mainTopic, subtopic, diffval) {
    const lastAttemptedRef = ref(
      database,
      `Users/${userId}/Overall/AttemptedSubtopicNumber/${diffval}/${subtopic}_question_attempt`
    );
    const lastAttemptedQuestionSnapshot = await get(lastAttemptedRef);
    const lastAttemptedQuestion = lastAttemptedQuestionSnapshot.val();
    if (!lastAttemptedQuestion) {
      const question = await fetchQuestionBySequenceId(
        mainTopic,
        subtopic,
        1,
        diffval
      );
      setQuestion(question);
    } else {
      const question = await fetchQuestionBySequenceId(
        mainTopic,
        subtopic,
        lastAttemptedQuestion + 1,
        diffval
      );
      if (question === null) {
        setIsModalVisibleForExhusted(true);
      }
      if (question !== undefined) {
        setQuestion(question);
      }
    }
    setIsLoading(false);
  }

  const fetchNextQuestion = useCallback(
    async (topicval) => {
      setIsLoading(true);
      setMainTopic(topicval);
      const result = await fetchRandomSubtopicFromTopic(topicval);
      setSubTopic(result.subtopicName);
      setSelectedLevel(result.diffName);
      fetchQuestionForUser(topicval, result.subtopicName, result.diffName);
    },
    [fetchQuestionForUser, fetchRandomSubtopicFromTopic]
  );

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [timer]);

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = () => {
    if (selectedOption !== null) {
      if (selectedOption === question.Answer) {
        setIsCorrect(true);
        UpdateTheMarksOfSubtopic(subTopic, selectedLevel, 1);
      } else {
        UpdateTheMarksOfSubtopic(subTopic, selectedLevel, -1);
        setIsCorrect(false);
      }
      setIsNextButtonDisabled(false);
      updateTimeAvgForSubtopic();
      setShowSolution(true);
    } else {
      message.error("Please Select the Answer!");
    }
  };

  const updateTimeAvgForSubtopic = () => {
    stopTimer();
    const dataRef = ref(
      database,
      `Users/${userId}/Overall/AvgTimeOfSubtopic/${selectedLevel}/${subTopic}_time`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (currentData === null) {
          return set(dataRef, timer);
        }
        return set(dataRef, ((currentData || 0) + timer) / 2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const dataRefDaily = ref(
      database,
      `Users/${userId}/Daily/${todaysDate}/AvgTimeOfSubtopic/${selectedLevel}/${subTopic}_time`
    );
    get(dataRefDaily)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (currentData === null) {
          return set(dataRefDaily, timer);
        }
        return set(dataRefDaily, ((currentData || 0) + timer) / 2);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UpdateTheMarksOfSubtopic = (subTopic, selectedLevel, value) => {
    const dataRef = ref(
      database,
      `Users/${userId}/Overall/MarksOfSubtopic/${selectedLevel}/${subTopic}_mark`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (value < 0 && (!currentData || currentData <= 0)) {
          return set(dataRef, 0);
        }
        return set(dataRef, (currentData || 0) + value);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const dataRefDaily = ref(
      database,
      `Users/${userId}/Daily/${todaysDate}/MarksOfSubtopic/${selectedLevel}/${subTopic}_mark`
    );
    get(dataRefDaily)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (value < 0 && (!currentData || currentData <= 0)) {
          return set(dataRefDaily, 0);
        }
        return set(dataRefDaily, (currentData || 0) + value);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleNext = () => {
    if (showSolution === false) {
      message.error("Click on Submit!");
      return;
    }
    if (todaysQuestions + 1 >= questionLimit) {
      database
        .ref(
          `Users/${userId}/Overall/AttemptedSubtopicNumber/${selectedLevel}/${subTopic}_question_attempt`
        )
        .set(question.sequence_id);

      const dataRef = ref(
        database,
        `Users/${userId}/Daily/${todaysDate}/AttemptedSubtopicNumber/${selectedLevel}/${subTopic}_question_attempt`
      );
      get(dataRef)
        .then((snapshot) => {
          const currentData = snapshot.val();
          if (!currentData || currentData <= 0) {
            return set(dataRef, 0);
          }
          return set(dataRef, (currentData || 0) + 1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      database.ref(`Users/${userId}/todays_question`).set(todaysQuestions + 1);
      database
        .ref(`Users/${userId}/Overall/total_question_Attempt`)
        .set(overallQuestions + 1);
      database
        .ref(`Users/${userId}/Daily/${todaysDate}/total_question_Attempt`)
        .set(dailyQuestions + 1);
      setIsModalRedirectVisible(true);
      return;
    }
    setSelectedOption(null);
    setIsCorrect(null);
    const dataRefOv = ref(
      database,
      `Users/${userId}/Overall/AttemptedSubtopicNumber/${selectedLevel}/${subTopic}_question_attempt`
    );
    get(dataRefOv)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (!currentData) {
          return set(dataRefOv, 1).then(() => {
            fetchNextQuestion(mainTopic);
          });
        }
        return set(dataRefOv, currentData + 1).then(() => {
          fetchNextQuestion(mainTopic);
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const dataRef = ref(
      database,
      `Users/${userId}/Daily/${todaysDate}/AttemptedSubtopicNumber/${selectedLevel}/${subTopic}_question_attempt`
    );
    get(dataRef)
      .then((snapshot) => {
        const currentData = snapshot.val();
        if (!currentData) {
          return set(dataRef, 1);
        }
        return set(dataRef, currentData + 1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setShowSolution(false);
    const updatedQuestionsCount = todaysQuestions + 1;
    const updatedOverallQuestionCount = overallQuestions + 1;
    const updateDailyQuestionCount = dailyQuestions + 1;
    setOverallQuestions(updatedOverallQuestionCount);
    setTodaysQuestions(updatedQuestionsCount);
    setDailyQuestions(updateDailyQuestionCount);
    setTimer(0);
    database.ref(`Users/${userId}/todays_question`).set(updatedQuestionsCount);
    database
      .ref(`Users/${userId}/Overall/total_question_Attempt`)
      .set(updatedOverallQuestionCount);
    database
      .ref(`Users/${userId}/Daily/${todaysDate}/total_question_Attempt`)
      .set(updateDailyQuestionCount);

    setIsNextButtonDisabled(true);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  }

  const showConfirmModal = () => {
    setIsModalVisible(true);
  };
  // payment process START

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handlePaymentSuccess = async (response) => {
    const paymentRecord = {
      paymentId: response.razorpay_payment_id,
      orderId: response.razorpay_order_id,
      signature: response.razorpay_signature,
      createdAt: new Date().toISOString(),
    };

    try {
      const userId = user.uid;
      await database.ref(`Payments/${userId}`).push(paymentRecord);
      await database.ref(`Users/${userId}`).update({ premium: true });
      navigate("/dashboard");
    } catch (error) {
      console.error("Error saving payment record:", error);
    }
  };

  const loadRazorpay = (details) => {
    const options = {
      key: "rzp_live_bMK7LvizPK6oVa",
      amount: details.amount,
      currency: details.currency,
      name: "AI CAT EXAM PREP",
      description: "Payment for CAT Exam Prep",
      order_id: details.id,
      handler: function (response) {
        handlePaymentSuccess(response);
      },
      prefill: {
        name: user.displayName,
        email: user.email,
        contact: "",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const processPayment = async (paymentData) => {
    try {
      const response = await fetch(
        "https://asia-southeast1-catexam-ebfb8.cloudfunctions.net/processPayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paymentData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const orderDetails = await response.json();
      loadRazorpay(orderDetails);
    } catch (error) {
      console.error("Error during the payment process:", error);
    }
  };

  const handlePayment = () => {
    const paymentDetails = {
      amount: 49900,
    };
    processPayment(paymentDetails);
  };

  //  payment process END

  const handleChangetopic = async (value) => {
    setIsModalVisibleForExhusted(false);
    setQuecard(false);
    setMainTopic(value);
    setTimer(0);
    setShowSolution(false);
    fetchNextQuestion(value);
  };

  return (
    <AfterLoginLayout>
      {isLoading ? (
        <div style={{ textAlign: "center", paddingTop: "20%" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Button
            type="primary"
            className="btn-login back"
            onClick={showConfirmModal}
            style={{
              marginLeft: "45px",
              marginTop: "15px",
            }}
          >
            Back
          </Button>
          <div className="app">
            <Row className="timer-section" gutter={[20, 0]}>
              <Col xs={18} md={20} lg={18}>
                <Card className="Main-timer-card">
                  <Row>
                    <Space>
                      <Card className="Inner-timer-card">
                        <span>
                          {todaysQuestions === null || 0 ? 1 : todaysQuestions}/
                          {questionLimit}
                        </span>
                      </Card>
                      <span>
                        Time:{" "}
                        {selectedLevel === "None" ? "00:00" : formatTime(timer)}
                      </span>
                    </Space>
                  </Row>
                </Card>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={6}
                className="stop"
                style={{ marginTop: "0.2rem" }}
              >
                <img
                  src={stop}
                  width={30}
                  height={30}
                  alt="Stop"
                  style={{ cursor: "pointer" }}
                  onClick={showConfirmModal}
                />
              </Col>
            </Row>
            <Card className="Topic-section">
              <Row>
                <Col lg={12}>
                  <span className="MainTopicText">
                    Select your Topic : &nbsp;
                    <Select
                      defaultValue="Select the Topic to start"
                      style={{ width: "fit-content" }}
                      value={mainTopic}
                      onChange={handleChangetopic}
                      options={[
                        {
                          value: "Reading Comprehension",
                          label: "Reading Comprehension",
                        },
                        {
                          value: "Quantitative Ability",
                          label: "Quantitative Ability",
                        },
                        { value: "Verbal Ability", label: "Verbal Ability" },
                        {
                          value: "Logical Reasoning",
                          label: "Logical Reasoning",
                        },
                        {
                          value: "Data Interpretation",
                          label: "Data Interpretation",
                        },
                      ]}
                    />
                  </span>
                </Col>
                <Col lg={12}>
                  <span
                    style={{ display: "flex", justifyContent: "end" }}
                    className="MainTopicText"
                  >
                    Difficulty: {selectedLevel}
                  </span>
                </Col>
              </Row>
            </Card>
            <Card
              className="question-section"
              hidden={quecard}
              bordered={false}
            >
              <br />
              <span
                className="labelText"
                style={{
                  display:
                    mainTopic === "Reading Comprehension" ? "block" : "none",
                }}
              >
                Passage:
              </span>
              <span dangerouslySetInnerHTML={{ __html: question?.passage }} />
              <br />
              <span className="labelText">Question:</span>
              <span dangerouslySetInnerHTML={{ __html: question?.question }} />
              <br />
              <span className="labelText"> Options:</span>
              <br />
              <Radio.Group
                disabled={showSolution}
                onChange={handleOptionChange}
                value={selectedOption}
              >
                <Radio
                  style={{
                    display: "flex",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionA}
                  value="A"
                >
                  <br />
                  <span>A)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionA }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionB}
                  value="B"
                >
                  <br />
                  <span>B)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionB }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionC}
                  value="C"
                >
                  <br />
                  <span>C)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionC }}
                  />
                  <br />
                </Radio>
                <Radio
                  style={{
                    display: "flex",
                    minHeight: "30px",
                    lineHeight: "20px",
                    color: "#fafafa",
                    fontSize: "medium",
                  }}
                  key={question?.optionD}
                  value="D"
                >
                  <br />
                  <span>D)</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: question?.optionD }}
                  />
                  <br />
                </Radio>
              </Radio.Group>
              <br />
              <br />

              <div className="button-section">
                <Space>
                  <Button
                    style={{ display: isNextButtonDisabled ? "block" : "none" }}
                    className="btn-login"
                    size="large"
                    type="primary"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{ display: isNextButtonDisabled ? "none" : "block" }}
                    className="btn-login"
                    size="large"
                    type="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Space>
              </div>
              <br />
              <div style={{ display: showSolution ? "block" : "none" }}>
                <CloseCircleTwoTone
                  style={{
                    fontSize: "42px",
                    display: isCorrect ? "none" : "flex",
                  }}
                  twoToneColor="#FF3131"
                />
                <CheckCircleTwoTone
                  style={{
                    fontSize: "42px",
                    display: isCorrect ? "flex" : "none",
                  }}
                  twoToneColor="#23FD00"
                />
              </div>
              <br />
              {showSolution && (
                <Alert
                  message={
                    <>
                      <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Selected Answer :{" "}
                        </span>
                        {selectedOption}
                      </span>
                      <br />
                      <span style={{ fontSize: "medium", fontStyle: "bold" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Correct Answer :{" "}
                        </span>{" "}
                        {question?.Answer}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "medium",
                          fontStyle: "bold",
                          fontWeight: "bold",
                        }}
                      >
                        Solution :
                      </span>
                      <br />
                      <span
                        style={{ fontSize: "medium" }}
                        dangerouslySetInnerHTML={{ __html: question?.solution }}
                      />
                      <div
                        style={{
                          display:
                            mainTopic === "Quantitative Ability"
                              ? "block"
                              : "none",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "medium",
                            fontStyle: "bold",
                            fontWeight: "bold",
                          }}
                        >
                          Short Cut Trick :
                        </span>
                        <br />
                        <span
                          style={{ fontSize: "medium" }}
                          dangerouslySetInnerHTML={{
                            __html: question?.shortcut,
                          }}
                        />
                      </div>
                    </>
                  }
                  type="info"
                />
              )}
            </Card>
          </div>
        </>
      )}
      <Modal
        title="Confirm Quit"
        open={isModalVisible}
        onOk={() => navigate("/dashboard")}
        onCancel={() => setIsModalVisible(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to quit the practice session?</p>
      </Modal>
      <Modal
        title="Please change your topic!"
        open={isModalVisibleForExhusted}
        onOk={() => setIsModalVisibleForExhusted(false)}
        onCancel={() => setIsModalVisibleForExhusted(false)}
        footer=""
      >
        <span className="MainTopicText">
          Select your Topic : &nbsp;
          <Select
            defaultValue="Select the Topic to start"
            style={{ width: "fit-content" }}
            value={mainTopic}
            onChange={handleChangetopic}
            options={[
              {
                value: "Reading Comprehension",
                label: "Reading Comprehension",
              },
              {
                value: "Quantitative Ability",
                label: "Quantitative Ability",
              },
              { value: "Verbal Ability", label: "Verbal Ability" },
              {
                value: "Logical Reasoning",
                label: "Logical Reasoning",
              },
              {
                value: "Data Interpretation",
                label: "Data Interpretation",
              },
            ]}
          />
        </span>
      </Modal>
      <Modal
        title={userType ? "Great Job!" : "Limit Reached!"}
        open={isModalRedirectVisible}
        onOk={() => navigate("/report")}
        onCancel={() => setIsModalRedirectVisible(false)}
        okText="Yes"
        cancelText="No"
        footer=""
      >
        <p>
          {userType
            ? "Congratulations! You have successfully completed today's tasks. Keep up the good work!"
            : "You have reached today's usage limit."}
        </p>
        <Row justify={"space-evenly"} style={{ marginTop: "20px" }}>
          <Button
            disabled={userType}
            className="btn-login"
            style={{
              backgroundColor: "#0156d2",
              display: userType ? "none" : "block",
            }}
            onClick={handlePayment}
          >
            Upgrade to Pro
          </Button>
          <Button
            className="btn-login"
            style={{ backgroundColor: "#0156d2" }}
            onClick={() => navigate("/report")}
          >
            Report & Feedback
          </Button>
        </Row>
      </Modal>
    </AfterLoginLayout>
  );
}

export default PracticeQuestion;
