import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Layout,
  Menu,
  Row,
  Drawer,
  Divider,
  Avatar,
} from "antd";
import "../styles/landinPage.css";
import Meta from "antd/es/card/Meta";
import Lottie from "react-lottie";
import step3 from "../assets/Animation - 1707231783238.json";
import step1 from "../assets/Animation - 1707231667481.json";
import step2 from "../assets/Animation - 1707231728363.json";
import { scroller } from "react-scroll";
import { auth, database, googleAuthProvider } from "../firebase.js";
import { signInWithPopup } from "firebase/auth";
import { MenuOutlined } from "@ant-design/icons";
import { get, ref } from "firebase/database";
import vivek from "../assets/Vivek.jpg";
import jainil from "../assets/Jainil.jpg";

const { Header, Content, Footer } = Layout;
const item = [
  { key: "home", label: "Home" },
  { key: "about", label: "About" },
  // { key: "pricing", label: "Pricing" },
  { key: "login", label: "Login" },
];

const App = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [visible, setVisible] = useState(false);

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const features = [
    "10 AI Generated Question/day.",
    "1 CAT Questions",
    "Basic Report",
  ];
  const profeatures = [
    "50 AI Generated Question/day.",
    "Every Sunday Mock Paper.",
    "Advanced report that will help you understand your weaknesses and strength",
    "Advanced feedback and material suggestions by our AI",
    "Advance Data reporting",
    "Community support",
  ];
  const busfeatures = [
    "Customized AI-Generated Question/day.",
    "Every Sunday Mock Paper.",
    "Advanced report and analysis.",
    "Advanced feedback and material suggestions by our AI",
    "Advance Data reporting",
    "Community support",
    "Business Support",
  ];
  const step1Option = {
    loop: true,
    autoplay: true,
    animationData: step1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const step2Option = {
    loop: true,
    autoplay: true,
    animationData: step2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const step3Option = {
    loop: true,
    autoplay: true,
    animationData: step3,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleMenuClick = async (e) => {
    if (e.key === "login") {
      handleClick();
    } else {
      scroller.scrollTo(e.key, {
        duration: 2000,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };

  const handleClick = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      if (user) {
        const userDataNew = {
          name: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photoUrl: user.photoURL,
          premium: false,
        };
        setUserData(userDataNew);
      }
      await database.ref(`Users/${user?.uid}`).update(userData);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const emailRef = ref(database, `Users/${user?.uid}`);
        get(emailRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              navigate("/dashboard");
            }
          })
          .catch((error) => {
            console.error("Error checking user email in database:", error);
          });
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "2%",
          backgroundColor: "#151818",
          justifyContent: "flex-start",
        }}
      >
        <div className="logo">Daily IIM Prep</div>
        <div className="menu-container">
          <Menu
            theme="dark"
            mode="horizontal"
            className="LandingMenu"
            onClick={handleMenuClick}
            items={item}
          />
        </div>
        <Button className="menu-toggle" onClick={toggleDrawer}>
          <MenuOutlined />
        </Button>
        <Drawer
          title="Menu"
          placement="right"
          onClose={toggleDrawer}
          open={visible}
        >
          <Menu mode="vertical" onClick={handleMenuClick} items={item} />
        </Drawer>
      </Header>
      <Content className="main-content">
        <div id="home">
          <Row justify={"center"}>
            <Col xs={24} lg={17}>
              <h2 className="Title">AI-powered platform for CAT Preparation</h2>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col xs={22} lg={20}>
              <h3 className="Sub-Title">
                Empower your CAT exam preparation with our comprehensive
                platform, offering an extensive collection of unlimited practice
                questions and insightful result analysis.
              </h3>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col xs={12} md={12} lg={10} xl={8}>
              <Row className="btn" justify={"space-between"}>
                <Col xs={12} lg={12} className="buttons">
                  <Button
                    size="large"
                    className="join button"
                    onClick={handleClick}
                  >
                    Join Free
                  </Button>
                </Col>
                <Col xs={12} lg={12} className="buttons">
                  <Button size="large" className="button" onClick={handleClick}>
                    Log In
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row justify={"center"}>
          <Col xs={22} lg={16}>
            <h1 className="Second">3 Simple steps to score 90-95% @ CAT</h1>
          </Col>
        </Row>
        <div className="first step">
          <Row
            gutter={{ lg: [84, 0] }}
            style={{ marginBottom: "10%" }}
            justify={"center"}
          >
            <Col xs={22} sm={22} md={12} lg={8} xl={9}>
              <Lottie options={step1Option} height={300} width={300} />
            </Col>
            <Col xs={22} sm={22} md={12} lg={8} xl={9}>
              <Card className="card-content">
                <Meta
                  title="Step 1: Solve Unlimited AI-generated question"
                  description="Engage in daily practice with an array of fresh questions crafted in the likeness of NIFT queries. Consistent dedication to solving these AI-generated questions not only sharpens your skills but also accelerates your learning journey."
                />
              </Card>
            </Col>
          </Row>
        </div>
        <div className="second step">
          <Row
            gutter={{ lg: [84, 0] }}
            style={{ marginBottom: "10%" }}
            justify={"center"}
          >
            <Col xs={22} sm={22} md={12} lg={8} xl={9} order={1}>
              <Card className="card-content">
                <Meta
                  title="Step 2: Analysis"
                  description="Leverage our AI’s capabilities to receive a comprehensive report highlighting your strengths and areas for improvement. Delve into the insights provided, discern your weaker and stronger concepts, and use this valuable information to inform your targeted preparation for the next step."
                />
              </Card>
            </Col>
            <Col xs={22} sm={22} md={12} lg={8} xl={9} order={2}>
              <Lottie
                options={step2Option}
                height={350}
                className="img-resize2"
              />
            </Col>
          </Row>
        </div>
        <div className="third step">
          <Row
            gutter={{ lg: [84, 0] }}
            style={{ marginBottom: "10%" }}
            justify={"center"}
          >
            <Col xs={22} sm={22} md={12} lg={8} xl={9} order={1}>
              <Lottie options={step3Option} height={350} width={"90%"} />
            </Col>
            <Col xs={22} sm={22} md={12} lg={8} xl={9} order={2}>
              <Card className="card-content">
                <Meta
                  title="Step 3: Improve with AI-suggested resources."
                  description="Our AI will give you free resources like websites, videos, and other recommendations based on the analysis. Go through the topics to improve your concept and come back to take the test again."
                />
              </Card>
            </Col>
          </Row>
        </div>
        {/* <h1 className="Third-Title">Plans & Pricing</h1>
        <div id="pricing" className="pricing-section">
          <Row gutter={16} justify={"space-evenly"}>
            <Col xs={22} lg={8}>
              <Card title="Free" bordered={false} className="pricing-card">
                <p>For individuals</p>
                <h2>₹ 0</h2>
                <p>* Free Forever</p>
                <Button
                  type="primary"
                  size="large"
                  className="main-content button"
                  onClick={handleClick}
                >
                  Get Started
                </Button>
                <div className="features left">
                  {features.map((feature, index) => (
                    <p key={index}>{feature}</p>
                  ))}
                </div>
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card title="Pro" bordered={false} className="pricing-card">
                <p>For individuals</p>
                <h2>₹ 499/month</h2>
                <p>Every month</p>
                <Button
                  type="primary"
                  size="large"
                  className="main-content button"
                  onClick={handleClick}
                >
                  Get Started
                </Button>
                <div className="features left">
                  {profeatures.map((feature, index) => (
                    <p key={index}>{feature}</p>
                  ))}
                </div>
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card title="Business" bordered={false} className="pricing-card">
                <p>For individuals</p>
                <h2>Talk to us</h2>
                <p>Customized Based on Requirements</p>
                <Button
                  type="primary"
                  size="large"
                  className="main-content button"
                >
                  Talk to us
                </Button>
                <div className="features left">
                  {busfeatures.map((feature, index) => (
                    <p key={index}>{feature}</p>
                  ))}
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        <Row justify={"center"}>
          <Col span={3}>
            <Divider className="border-class" />
          </Col>
        </Row>
        <div id="about">
          <h1 className="Third-Title">About the creators</h1>
          <Row justify={"center"} style={{ margin: "5em 0px" }}>
            <Col xs={18} lg={10}>
              <Row justify={"center"}>
                <Col xs={12} lg={12} className="text-center">
                  <Avatar size={{ xs: 100, xl: 80, xxl: 100 }} src={vivek} />
                  <div style={{ marginTop: "1em", fontSize: "1.8em" }}>
                    Vivek Pujari
                  </div>
                  <div style={{ fontSize: "1em", lineHeight: "2em" }}>
                    Founder
                  </div>
                </Col>
                <Col xs={12} lg={12} className="text-center">
                  <Avatar size={{ xs: 100, xl: 80, xxl: 100 }} src={jainil} />
                  <div style={{ marginTop: "1em", fontSize: "1.8em" }}>
                    Jainil Patel
                  </div>
                  <div style={{ fontSize: "1em", lineHeight: "2em" }}>
                    Co-Founder
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col xs={22} md={16}>
              <h3 className="fourth-SubTitle">
                Made by students and graduates of CAT (National Institute of
                Fashion Technology), CATExam.com is an easy-to-use website
                powered by AI tools. It’s made just for students who want to do
                really well in the IIM entrance exam. Every year, over 1000+
                students reach out to us asking for advice on how to get ready
                for IIM.
              </h3>
              <h3 className="fourth-SubTitle">
                Our goal with this platform is to assist people all across
                India, no matter where they live, in preparing for the CAT exam
                more effectively.
              </h3>
            </Col>
          </Row>
        </div>
      </Content>

      <Row
        justify={"center"}
        style={{ margin: "2em 0px", backgroundColor: "#151818" }}
      >
        <Col span={18}>
          <Divider className="border-class" />
        </Col>
      </Row>

      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#151818",
          color: "#FAFAFA",
        }}
      >
        <Row className="footertag">
          <Col lg={8} xs={24}>
            LOGO
          </Col>
          <Col lg={8} xs={24} style={{ fontSize: "1.8em" }}>
            NIFT Exam
          </Col>
          <Col lg={8} xs={24} style={{ fontSize: "1.8em" }}>
            catexam ©{new Date().getFullYear()}
          </Col>
        </Row>
        <div className="disclaimer" style={{ marginTop: "5em" }}>
          Disclaimer: CATExam.com is an independent entity and is not affiliated
          with or endorsed by any IIM institutions or organizations.
        </div>
      </Footer>
    </Layout>
  );
};

export default App;
