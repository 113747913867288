import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Layout,
  message,
  Divider,
  List,
  Progress,
  Button,
  Spin,
  Modal,
} from "antd";
import AfterLoginLayout from "../components/AfterLoginLayout";
import Tooltip from "@uiw/react-tooltip";
import HeatMap from "@uiw/react-heat-map";
import { get, ref } from "firebase/database";
import { auth, database } from "../firebase";
import Donut from "../components/Donut";
import StackBar from "../components/StackBar";
import LineChart from "../components/LineChart";
import "../styles/Dashboard.css";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";

const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];

const Dashboard = () => {
  const { Meta } = Card;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [justify, setJustify] = useState("center");
  const [recsize, setRecsize] = useState(11);
  const [chartsize, setChartsize] = useState("80%");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("Today");
  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  );
  const [totalAttemptsWithDiff, setTotalAttemptsWithDiff] = useState({
    Easy: 0,
    Medium: 0,
    Hard: 0,
  });
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [dateWiseAttempt, setDateWiseAttempt] = useState([
    { date: "2021/02/11", count: -1 },
  ]);
  const twoColors = { "0%": "#108ee9", "100%": "#87d068" };

  const handleStartnow = () => {
    navigate("/practice-question");
  };

  useEffect(() => {
    setSelectedPeriod("Today");
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const difficulties = ["Easy", "Medium", "Hard"];
        const totalAttempts = {};
        setIsLoading(true);
        const fetchUserData = async () => {
          try {
            const userRef = ref(database, `Users/${user.uid}`);
            const snapshot = await get(userRef);
            const userData = snapshot.val();
            if (userData) {
              setUser(userData);
              const snapshotForTotal = await database
                .ref(`Users/${user.uid}/Overall/total_question_Attempt`)
                .once("value");
              const TotalattemptsData = snapshotForTotal.val();
              setTotalAttempts(((TotalattemptsData / 5000) * 100).toFixed(2));
              for (const difficulty of difficulties) {
                const snapshot = await database
                  .ref(
                    `Users/${user.uid}/Overall/AttemptedSubtopicNumber/${difficulty}`
                  )
                  .once("value");
                const attemptsData = snapshot.val();
                let total = 0;
                if (attemptsData) {
                  Object.values(attemptsData).forEach((attemptCount) => {
                    total += attemptCount;
                  });
                }
                totalAttempts[difficulty] = ((total / 1600) * 100).toFixed(2);
              }
              setTotalAttemptsWithDiff(totalAttempts);
              const data = await fetchData(user);
              setDateWiseAttempt(data);
            }
          } catch (error) {
            message.error(`Can Not fetch User Data`);
          } finally {
            setIsLoading(false);
          }
        };
        fetchUserData();
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const updateDateRange = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setJustify("center");
        setRecsize(12);
        setChartsize("100%");
        setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 6)));
      } else {
        setJustify("space-between");
        setRecsize(14);
        setChartsize("70%");
        setStartDate(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        );
      }
    };

    window.addEventListener("resize", updateDateRange);
    updateDateRange();

    return () => window.removeEventListener("resize", updateDateRange);
  }, []);

  async function fetchData(user) {
    const userRef = database.ref(`Users/${user.uid}/Daily`);
    try {
      const snapshot = await userRef.once("value");
      const dailyData = snapshot.val();
      if (dailyData) {
        const formattedData = Object.keys(dailyData).map((date) => {
          const formattedDate = `${date.substring(0, 4)}/${date.substring(
            4,
            6
          )}/${date.substring(6, 8)}`;

          return {
            date: formattedDate,
            count: dailyData[date].total_question_Attempt || 0,
          };
        });

        return formattedData;
      } else {
        console.log("No data available");
        return [];
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
  const handleMockTest = () => {
    setIsModalVisible(true);
  };
  return (
    <>
      <TopBar />
      <AfterLoginLayout>
        {isLoading ? (
          <div style={{ textAlign: "center", paddingTop: "20%" }}>
            <Spin size="large" />
          </div>
        ) : (
          <Layout className="layout">
            <Row justify={"center"}>
              <Col className="empty-space" xs={1} lg={4}></Col>
              <Col xs={22} lg={16}>
                <Row justify={"space-between"}>
                  <Col xs={24} lg={11} style={{ marginTop: "8%" }}>
                    <Card className="card-style" style={{ width: "100%" }}>
                      <Row justify={"center"}>
                        <Col lg={24} xs={24}>
                          <div className="card-text">
                            Hi, {user.name}
                            <br />
                            Start your Practice now!
                            <br />
                            <Button
                              onClick={handleStartnow}
                              className="btn-login start-btn"
                            >
                              Start Now
                            </Button>
                          </div>
                        </Col>
                        {/* <Divider type="vertical" style={{ height: "100%" }} /> */}
                        {/* <Col lg={11} xs={24}>
                          <div className="card-text">
                            Your Mock Is Ready!
                            <br />
                            <Button
                              className="btn-login"
                              onClick={handleMockTest}
                            >
                              Mock Test
                            </Button>
                          </div>
                        </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col xs={24} lg={11} style={{ marginTop: "8%" }}>
                    <Card className="card-style" style={{ width: "100%" }}>
                      <span className="second-card-header">Performance</span>
                      <Row>
                        <Col
                          xs={8}
                          lg={8}
                          style={{
                            height: "100%",
                            textAlign: "center",
                            alignSelf: "center",
                          }}
                        >
                          <Progress
                            type="circle"
                            percent={totalAttempts}
                            strokeColor={twoColors}
                          />
                        </Col>
                        <Col xs={15} lg={15}>
                          <span>Easy</span>
                          <Progress
                            percent={totalAttemptsWithDiff?.Easy}
                            strokeColor={twoColors}
                          />
                          <span>Medium</span>
                          <Progress
                            percent={totalAttemptsWithDiff?.Medium}
                            strokeColor={twoColors}
                          />
                          <span>Hard</span>
                          <Progress
                            percent={totalAttemptsWithDiff?.Hard}
                            strokeColor={twoColors}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Divider />

                <Row justify={"center"}>
                  <Col xs={24} lg={24}>
                    <Card className="card-style" style={{ width: "100%" }}>
                      <span className="second-card-header">Activity</span>
                      <HeatMap
                        value={dateWiseAttempt}
                        width={"100%"}
                        startDate={startDate}
                        endDate={today}
                        rectSize={recsize}
                        style={{
                          color: "#fafafa",
                          "--rhm-rect-active": "white",
                        }}
                        weekLabels={[
                          "Sun",
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thu",
                          "Fri",
                          "Sat",
                        ]}
                        rectProps={{
                          rx: 10,
                        }}
                        rectRender={(props, data) => {
                          if (data.count === -1) {
                            return <rect {...props} fill="transparent" />;
                          } else {
                            return (
                              <Tooltip
                                placement="top"
                                content={`Count: ${data.count} & Date: ${data.date}`}
                              >
                                <rect {...props} />
                              </Tooltip>
                            );
                          }
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Divider />

                <Row justify={justify}>
                  <Col lg={24} xs={24}>
                    <Card
                      className="card-style"
                      title="Topic Analysis"
                      bordered={false}
                      style={{
                        width: "100%",
                        height: "fit-content",
                        marginBottom: "50px",
                      }}
                    >
                      <Row>
                        <Col lg={16} xs={24}>
                          <Donut userData={user} Period={selectedPeriod} />
                        </Col>
                        <Col lg={8} xs={24}>
                          <Row
                            className="second-card-header"
                            justify={"center"}
                          >
                            Tips
                          </Row>
                          <br />
                          <List
                            size="large"
                            dataSource={data}
                            renderItem={(item) => (
                              <List.Item className="feedback-content">
                                {item}
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Card
                      className="card-style"
                      title="Error Rate"
                      bordered={false}
                      style={{
                        width: "100%",
                        height: "fit-content",
                        marginBottom: "50px",
                      }}
                    >
                      <Row>
                        <Col lg={16} xs={24}>
                          <StackBar userData={user} Period={selectedPeriod} />
                        </Col>
                        <Col lg={8} xs={24}>
                          <Row
                            className="second-card-header"
                            justify={"center"}
                          >
                            Tips
                          </Row>
                          <br />
                          <List
                            size="large"
                            dataSource={data}
                            renderItem={(item) => (
                              <List.Item className="feedback-content">
                                {item}
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col lg={24} xs={24}>
                    <Card
                      className="card-style"
                      title="Questions Attempted"
                      bordered={false}
                      style={{
                        width: "100%",
                        height: "fit-content",
                        marginBottom: "50px",
                      }}
                    >
                      <Row>
                        <Col lg={16} xs={24}>
                          <LineChart userData={user} Period={selectedPeriod} />
                        </Col>
                        <Col lg={8} xs={24}>
                          <Row
                            className="second-card-header"
                            justify={"center"}
                          >
                            Tips
                          </Row>
                          <br />
                          <List
                            size="large"
                            dataSource={data}
                            renderItem={(item) => (
                              <List.Item className="feedback-content">
                                {item}
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Divider />
                <Col lg={24} xs={24}>
                  <Card className="card-style" style={{ width: "100%" }}>
                    <Row className="second-card-header" justify={"start"}>
                      FeedBack
                    </Row>
                    <br />
                    <List
                      size="large"
                      dataSource={data}
                      renderItem={(item) => (
                        <List.Item className="feedback-content">
                          {item}
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Col>
              <Col className="empty-space" xs={1} lg={4}></Col>
            </Row>
          </Layout>
        )}
        <Modal
          title="Mock Test"
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer=""
        >
          <p>Mock Test Are Coming Soon</p>
        </Modal>
      </AfterLoginLayout>
    </>
  );
};

export default Dashboard;
