import { Line } from "@ant-design/plots";
import React, { PureComponent } from "react";

export default function LineChart({ userData, Period }) {
  const data = [
    { year: "01/03/2024", Easy: 3, Medium: 10, Hard: 2 },
    { year: "02/03/2024", Easy: 4, Medium: 15, Hard: 5 },
    { year: "03/03/2024", Easy: 3.5, Medium: 20, Hard: 6 },
    { year: "04/03/2024", Easy: 1, Medium: 7, Hard: 7 },
    { year: "05/03/2024", Easy: 8.9, Medium: 2, Hard: 8 },
    { year: "06/03/2024", Easy: 1, Medium: 4, Hard: 2 },
    { year: "07/03/2024", Easy: 8.9, Medium: 10, Hard: 9 },
  ];
  const config = {
    data: {
      value: data,
      transform: [
        {
          type: "fold",
          fields: ["Easy", "Medium", "Hard"],
          key: "type",
          value: "value",
        },
      ],
    },
    xField: "year",
    yField: "value",
    theme: "classicDark",
    legend: { size: true },
    colorField: "type",
    shapeField: "smooth",
    axis: {
      x: { labelAutoHide: "greedy" },
    },
    point: {
      sizeField: 4,
      style: {
        fill: "#2b2b2b",
        lineWidth: 2,
      },
    },
  };

  return <Line {...config} />;
}
