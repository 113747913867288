import { Column } from "@ant-design/plots";
import React, { useCallback, useEffect, useState } from "react";

export default function StackBar({ userData, Period }) {
  const datanew = [
    { name: "QA", seconds: 0.0 },
    { name: "GK & CA", seconds: 0.0 },
    { name: "CA", seconds: 0.0 },
    { name: "A & LA", seconds: 0.0 },
  ];

  const [chartData, setChartData] = useState([]);

  const mapSubtopicToTopic = (subtopic) => {
    const mappings = {
      "Age-Related Problems": "A & LA",
      "Analytical Puzzles": "A & LA",
      "Blood Relations": "A & LA",
      "Logical Ability": "A & LA",
      "Other LR": "A & LA",
      Comprehension: "EC",
      Antonyms: "CA",
      "Correct spellings": "CA",
      "Figurative Language": "CA",
      "Idioms and phrases": "CA",
      "Metaphorical Meanings": "CA",
      "One word substitutes": "CA",
      Plural: "CA",
      Singular: "CA",
      Synonyms: "CA",
      "Words with corresponding meanings": "CA",
      "Words with phrases": "CA",
      "Creative Ability Test": "CAT",
      "General Knowledge": "GK & CA",
      "Current Affairs": "GK & CA",
      Addition: "QA",
      Average: "QA",
      "Boats and Streams": "QA",
      Distance: "QA",
      Division: "QA",
      Fractions: "QA",
      Multiplication: "QA",
      Percentage: "QA",
      Probability: "QA",
      "Rate of Interest": "QA",
      "Ratio and Proportion": "QA",
      Statistics: "QA",
      "Work and Task": "QA",
    };

    return mappings[subtopic.split("_")[0]] || "Other";
  };

  const fetchAndReshapeData = useCallback(async (UserData, period) => {
    try {
      const today = new Date();
      let datesToConsider = [];

      switch (period) {
        case "Today":
          datesToConsider.push(
            today.toISOString().split("T")[0].replace(/-/g, "")
          );
          break;
        case "Past Week":
          for (let i = 0; i < 7; i++) {
            let date = new Date();
            date.setDate(today.getDate() - i);
            datesToConsider.push(
              date.toISOString().split("T")[0].replace(/-/g, "")
            );
          }
          break;
        case "Past Month":
          for (let i = 0; i < 30; i++) {
            let date = new Date();
            date.setDate(today.getDate() - i);
            datesToConsider.push(
              date.toISOString().split("T")[0].replace(/-/g, "")
            );
          }
          break;
        case "Overall":
          datesToConsider = [UserData.Overall]; // Assuming Overall has the format required
          break;
        default:
          break;
      }
      const mainTopicTimes = {};

      datesToConsider.forEach((date) => {
        const data =
          period === "Overall"
            ? date.AvgTimeOfSubtopic
            : UserData?.Daily?.[date]?.AvgTimeOfSubtopic;

        if (data) {
          Object.keys(data).forEach((key) => {
            const mainTopic = mapSubtopicToTopic(key);
            const time = data[key];
            if (!mainTopicTimes[mainTopic]) {
              mainTopicTimes[mainTopic] = { totalTime: 0, count: 0 };
            }
            mainTopicTimes[mainTopic].totalTime += time;
            mainTopicTimes[mainTopic].count += 1;
          });
        } else {
          return [];
        }
      });

      const reshapedData = Object.keys(mainTopicTimes)?.map((mainTopic) => {
        const { totalTime, count } = mainTopicTimes[mainTopic];
        return { name: mainTopic, seconds: count > 0 ? totalTime / count : 0 };
      });

      return reshapedData;
    } catch (error) {
      console.error("Error fetching data: ", error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchAndReshapeData();
  }, [fetchAndReshapeData]);

  const data = [
    { year: "Quantitative Ability", month: "Easy", value: 3 },
    { year: "Quantitative Ability", month: "Medium", value: 5 },
    { year: "Quantitative Ability", month: "Hard", value: 6 },
    { year: "Logical Reasoning", month: "Easy", value: 4 },
    { year: "Logical Reasoning", month: "Medium", value: 7 },
    { year: "Logical Reasoning", month: "Hard", value: 8 },
    { year: "Verbal Ability", month: "Easy", value: 5 },
    { year: "Verbal Ability", month: "Medium", value: 12 },
    { year: "Verbal Ability", month: "Hard", value: 10 },
    { year: "Reading Comprehension", month: "Easy", value: 5 },
    { year: "Reading Comprehension", month: "Medium", value: 11 },
    { year: "Reading Comprehension", month: "Hard", value: 1 },
    { year: "Data Interpretation", month: "Easy", value: 11.9 },
    { year: "Data Interpretation", month: "Medium", value: 15.9 },
    { year: "Data Interpretation", month: "Hard", value: 3.9 },
  ];

  const config = {
    data,
    xField: "year",
    yField: "value",
    colorField: "month",
    theme: "classicDark",
    axis: {
      x: {
        labelAutoRotate: false,
        labelAutoWrap: true,
        labelFormatter: (text) => {
          return text.replace(" ", "\n");
        },
      },
      y: {},
    },
    style: {
      inset: 5,
    },
    group: true,
  };

  return <Column {...config} />;
}
