import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyACLa8jq5pkILXaxlF0Zi2P04bja0XdQCo",
  authDomain: "catexam-ebfb8.firebaseapp.com",
  databaseURL:
    "https://catexam-ebfb8-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "catexam-ebfb8",
  storageBucket: "catexam-ebfb8.appspot.com",
  messagingSenderId: "374673212581",
  appId: "1:374673212581:web:209c21ad299c6b8f95f94c",
  measurementId: "G-4168HRN1GB",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const auth = getAuth(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();
// const analytics = getAnalytics(firebaseApp);

// export { auth, database, googleAuthProvider, analytics };
export { auth, database, googleAuthProvider };
