import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import PracticeQuestion from "./pages/PracticeQuestion";
import Dashboard from "./pages/Dashboard";
import LandingScreen from "./pages/index";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/practice-question" element={<PracticeQuestion />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
